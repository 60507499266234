import React, {
  useContext,
  useReducer,
  useEffect,
  useCallback,
  useRef,
  useState,
} from "react";
import {
  View,
  FlatList,
  SectionList,
  KeyboardAvoidingView,
  Dimensions,
  Keyboard,
  TouchableOpacity,
} from "react-native";
import SearchManager from "@presto-services/features/search/SearchManager";
import ThemeContext from "@presto-contexts/ThemeContext";
import SearchableScreen from "@presto-screens/SearchableScreen/SearchableScreen";
import ItemCard from "@presto-screen-components/ItemCard/ItemCard";
import { usePagination } from "../../hooks/pagination";
import { VerticalSpacing } from "@presto-components/Spacing";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import I18n from "i18n-js";
import _ from "lodash";
import { LineDivider } from "@presto-components/Dividers/Dividers";
import { RowButton } from "@presto-components/PrestoButton";
import AsyncStorage from "@react-native-community/async-storage";
import { useSafeArea } from "react-native-safe-area-context";
import PrestoText from "@presto-components/PrestoText";
import AnalyticsManager from "../../common/AnalyticsManager";
import { useCatalog } from "@presto-stores/CatalogStore";
import LoadingContainer from "@presto-components/LoadingContainer";
import asyncify from "@presto-common/Asyncify";
import config from "@presto-common/config";


const AsyncSearchManager = asyncify(SearchManager);

const windowHeight = Dimensions.get("window").height;

export function reducer(state, action) {
  const { payload } = action;
  switch (action.type) {
    case "SET_SEARCH_QUERY":
      return {
        ...state,
        ...{ query: payload, auto_complete_results: [] },
      };
    case "SET_SEARCH_FILTER":
      return {
        ...state,
        ...payload,
      };
    case "SET_SORT_TYPE":
      return {
        ...state,
        sort_type: payload,
      };
    case "SET_SEARCH_RESULTS":
      return { ...state, search_results: payload, auto_complete_results: [] };
    case "SET_RECENT_SEARCHES":
      return { ...state, recent_searches: payload };

    case "SET_AUTO_COMPLETE_RESULTS":
      return { ...state, auto_complete_results: _.slice(payload, 0, 7) };
  }
  return state;
}

export default function SearchScreen({ navigation, route }) {
  const searchParams = route.params.searchParams;
  const catalog = useCatalog((state) => state.catalog);
  const catalogId = route.params.catalogId;
  const showSimilar = route.params?.showSimilar;
  const { useMultiSearch = false } = route.params;
  const { theme } = useContext(ThemeContext);
  const { Navigator } = useContext(NavigatorContext);
  const [dataRender, setDataRender] = useState();
  const [screenLoading, setScreenLoading] = useState(false);
  const [state, dispatch] = useReducer(reducer, {
    query: searchParams.query,
    category_id: searchParams.category?.id,
    company_name: searchParams.company_name,
    distributor: searchParams.distributor,
    brand_name: searchParams.brand_name,
    molecule: searchParams.molecule,
    therapy_area_simple: searchParams.therapy_area_simple,
    sort_type: "listing",
    on_offer: undefined,
    auto_complete_results: [],
    is_new: searchParams.is_new || false,
    bestsellers: searchParams.bestsellers || false,
    price_range: searchParams.price_range || null,
    region: searchParams.region || null,
  });
  const ref = useRef({
    ...state,
    molecule: searchParams?.molecule,
  });

  const insets = useSafeArea();

  const isSearchEmpty = () => {
    return (
      _.isEmpty(state.query) &&
      _.isEmpty(state.category_id) &&
      _.isEmpty(state.company_name) &&
      _.isEmpty(state.distributor) &&
      _.isEmpty(state.region) &&
      _.isEmpty(state.brand_name) &&
      _.isEmpty(state.molecule) &&
      _.isEmpty(state.therapy_area_simple) &&
      !state.is_new &&
      !state.bestsellers
    );
  };

  //TODO: Search for exact match for name in first api call
  const searchPart1 = () => {
    const params = {
      name: ref.current.query,
      catalog_id: catalogId || catalog?.id,
      brand_name: ref.current.brand_name || ref.current.query,
      molecule: ref.current.molecule || ref.current.query,
    };
    if (!config?.features?.show_all){
      params.is_available=true
    }
      return AsyncSearchManager.strictSearch(params).then(
        ([error, response]) => {
          let items = _.get(response, "hits.hits", []);
          return items;
        }
      );
  };

  //TODO: Search for exact match for name in first api call
  const searchPart4 = () => {
    const params = {
      name: ref.current.query,
      catalog_id: catalogId || catalog?.id,
      sponsored: "yes",
      sold_out: "false",
      brand_name: ref.current.brand_name || ref.current.query,
      molecule: ref.current.molecule || ref.current.query,
    };
    if (!config?.features?.show_all) {
      params.is_available = true;
    }
    return AsyncSearchManager.strictSearch(params).then(([error, response]) => {
      let items = _.get(response, "hits.hits", []);
      return items;
    });
  };

  //TODO: Search for fuzzy match for molecule or any other fields in third api call
  const searchPart3 = (pageNumber = 1) => {
    let params = {
      category_id: ref.current.category_id,
      search_string: ref.current.query,
      company_name: ref.current.company_name,
      distributor: ref.current.distributor,
      brand_name: ref.current.brand_name,
      region: ref.current.region,
      molecule: ref.current.molecule,
      therapy_area_simple: ref.current.therapy_area_simple,
      on_variation_offer: ref.current.on_offer,
      [ref.current.sort_type]: true,
      page: pageNumber,
      is_new: ref.current.is_new,
      bestsellers: ref.current.bestsellers,
      price_range: ref.current.price_range,
      catalog_id: catalogId || catalog?.id,
      size: "100",
    };
    if (!config?.features?.show_all) {
      params.is_available = true;
    }

    if (_.isEmpty(ref.current.sort_type)) {
      params.name_asc = true;
    }
    console.log(params,"params2345===============");
    return AsyncSearchManager.fuzzySearchCategoryItem(params).then(
      ([error, response]) => {
        console.log(response, "response===============");
        let items = _.get(response, "hits.hits", []);
        // items = filterSoldOut(items, [], []);
        return items;
      }
    );
  };

  const liftReleventQueryStartingWith = (items, query) => {
    items.sort((obj1, obj2) => {
      let hasStartingQuery = _.startsWith(
        _.lowerCase(obj1._source.name),
        _.lowerCase(query)
      );

      if (hasStartingQuery) {
        return -1;
      }

      return 1;
    });
    return items;
  };

  const searchPart5 = () => {
    const params = {};
    return AsyncSearchManager.getSponsoredSlot(params).then(
      ([error, response]) => {
        return response;
      }
    );
  };

  const mergeSponsoredItem = (items, sponsored, slots) => {
    let pos = slots[0]?.placements;
    // let pos=[1,3,5,7];
    if (!pos) {
      return items;
    }
    let mergedArray = [];
    let j = 0;
    let i = 0;
    let c = 0;
    let total = items.length + sponsored.length;
    if (pos) {
      while (c < total) {
        let found = pos.find((ele) => ele?.position - 1 === c);
        if (found) {
          if (j < sponsored.length) {
            mergedArray.push(sponsored[j]);
            j++;
          } else {
            mergedArray.push(items[i]);
            i++;
          }
        } else {
          if (i < items.length) {
            mergedArray.push(items[i]);
            i++;
          } else {
            mergedArray.push(sponsored[j]);
            j++;
          }
        }
        c++;
      }
    }
    return mergedArray;
  };

  const filterSoldOut = (item, sponsoredItems, slots) => {
    let soldOutItem = item.filter((ele) => {
      return config?.features?.show_all
        ? ele?._source?.is_available === false
        : ele?._source?.sold_out === true;
    });
    let unSoldOutItem = item.filter((ele) => {
      return config?.features?.show_all
        ? ele?._source?.is_available === true
        : ele?._source?.sold_out === false;
    });
    let items = mergeSponsoredItem(unSoldOutItem, sponsoredItems, slots);
    return [...items, ...soldOutItem];
  };

  const mergedSearch = (pageNumber) => {
    let promises = [
      ref.current.query ? searchPart1() : [],
      ref.current.query ? searchPart4() : [],
      searchPart3(pageNumber),
      searchPart5(),
    ];

    return Promise.all(promises).then(([items1, items2, items3, slots]) => {
      let sponsoredItems = items2;
      let items = _.uniqBy(
        _.compact(_.concat(items1, items3)),
        (e) => e._source.id
      );
      items = liftReleventQueryStartingWith(items, ref.current.query);
      items = filterSoldOut(items, sponsoredItems, slots);
      items = _.uniqBy(items, (e) => e._source.id);
      return items;
    });
  };

  const searchPage = (pageNumber, success, failure) => {
    if (useMultiSearch) {
      mergedSearch(pageNumber).then((items) => {
        success(items);
      });
    } else {
      searchPart3(pageNumber).then((items) => {
        items = filterSoldOut(items, [], []);
        items = _.uniqBy(items, (e) => e._source.id);
        success(items);
      });
    }
  };

  const [
    results,
    loadNextPage,
    loadFirstPage,
    loading,
    pageLoaded,
  ] = usePagination(searchPage);

  useEffect(() => {
    if (!_.isEmpty(searchParams)) {
      ref.current = { ...ref.current, ...searchParams };
    }
  }, [searchParams]);

  useEffect(() => {
    setScreenLoading(loading);
  }, [loading]);

  useEffect(() => {
    loadFirstPage();
  }, [
    state.query,
    state.sort_type,
    state.company_name,
    state.region,
    state.distributor,
    state.on_offer,
    state.price_range,
    state.molecule,
    searchParams,
  ]);

  console.log(state,ref,"state==============");

  const onBack = () => {
    navigation.goBack();
  };

  const renderItem = ({ item, index }) => {
    return (
      <ItemCard
        key={item._source.id}
        item={item._source}
        setParentLoading={setScreenLoading}
      />
    );
  };
  const onSortChanged = (key) => {
    dispatch({
      type: "SET_SORT_TYPE",
      payload: key,
    });

    ref.current.sort_type = key;
    scrollListToTop();
  };

  const onFilterChanged = (params) => {
    dispatch({
      type: "SET_SEARCH_FILTER",
      payload: params,
    });
    ref.current.distributor = params?.company_name;
    ref.current.on_offer = params?.on_offer;
    ref.current.price_range = params?.price_range;
    scrollListToTop();
  };

  const onSort = () => {
    AnalyticsManager.send("sort_items");
    Navigator.emit({
      event: "modal",
      params: {
        screenName: "SORT_OPTIONS_SCREEN",
        screenParams: {
          onSortChanged: onSortChanged,
          selectedSort: state.sort_type,
        },
      },
    });
  };

  const onFilter = () => {
    AnalyticsManager.send("filter_items");
    Navigator.emit({
      event: "modal",
      params: {
        screenName: "FILTER_OPTIONS_SCREEN",
        screenParams: {
          onFilterChange: onFilterChanged,
          selectedManufacturer: state.company_name,
          onOffer: state.on_offer,
          selectedPrice: state.price_range,
        },
      },
    });
  };

  const searchInputRef = useRef();
  const itemsListingRef = useRef();

  useEffect(() => {
    if (route.params.focus) {
      setTimeout(() => searchInputRef.current?.focus(), 100);
    }
    scrollListToTop();
  }, []);

  const renderSectionHeader = ({ section }) => {
    if (!section.title || section.title === "" || section.data?.length === 0) {
      return null;
    }
    return (
      <View
        style={{
          paddingHorizontal: theme.primaryPadding,
          marginTop: 15,
          height: 40,
          flexDirection: "row",
          alignItems: "flex-end",
        }}
      >
        <PrestoText fontStyle="medium" color={theme.paragraph}>
          {section.title || ""}
        </PrestoText>
      </View>
    );
  };

  const onSearchItemPress = (query) => {
    onSearch(_.toLower(query));
  };

  const onSearch = async (text) => {
    if (!_.isEmpty(text)) {
      ref.current = { ...ref.current, query: text };
      dispatch({ type: "SET_SEARCH_QUERY", payload: text });
      const recentSearchesJson = await AsyncStorage.getItem("RECENT_SEARCHES");
      const recentSearches = recentSearchesJson
        ? JSON.parse(recentSearchesJson)
        : [];
      const filteredSearches = _.filter(recentSearches, (s) => s !== text);
      const newRecentSearches = [text, ...filteredSearches];
      const splicedSearches = _.slice(newRecentSearches, 0, 6);
      await AsyncStorage.setItem(
        "RECENT_SEARCHES",
        JSON.stringify(splicedSearches)
      );
    }
  };

  useEffect(() => {
    async function fet() {
      const recentSearchesJson = await AsyncStorage.getItem("RECENT_SEARCHES");
      dispatch({
        type: "SET_RECENT_SEARCHES",
        payload: recentSearchesJson ? JSON.parse(recentSearchesJson) : [],
      });
    }
    fet();
  }, []);

  const renderSearchItem = ({ item: query, index }) => {
    return (
      <View key={`index-${index}-query`}>
        <RowButton
          title={query}
          rightTitle={""}
          onPress={() => {
            onSearchItemPress(query);
          }}
          showDisclosure={true}
        />
        <LineDivider dividerColor="#DADADA" width="100%" theme={theme} />
      </View>
    );
  };

  const renderEmptySearchPage = () => {
    const sections = [
      {
        title: I18n.t("screen_messages.common.recent_searches"),
        key: "recent_searches",
        data: state.recent_searches || [],
      },
      {
        title: I18n.t("screen_messages.common.popular_searches"),
        key: "popular_searches",
        data: ["Paracetamol", "Ibucap", "Headache"],
      },
    ];
    return (
      <SectionList
        keyExtractor={(_, index) => "index-" + index}
        stickySectionHeadersEnabled={false}
        renderSectionHeader={renderSectionHeader}
        sections={sections}
        renderItem={renderSearchItem}
        layout={"default"}
        showsHorizontalScrollIndicator={false}
      />
    );
  };

  const loadAutoComplete = useCallback((text) => {
    const params = {
      category_id: ref.current.category_id,
      search_string: text,
      company_name: ref.current.company_name,
      region: ref.current.region,
      brand_name: ref.current.brand_name,
      molecule: ref.current.molecule,
      therapy_area_simple: ref.current.therapy_area_simple,
      on_variation_offer: ref.current.on_offer,
      [ref.current.sort_type]: true,
      page: 1,
      fuzziness: "AUTO",
      is_new: ref.current.is_new,
      catalog_id: catalogId,
    };

    SearchManager.fuzzySearchCategoryItem(
      params,
      (response) => {
        dispatch({
          payload: response.hits.hits,
          type: "SET_AUTO_COMPLETE_RESULTS",
        });
      },
      () => {}
    );
  }, []);

  const loadAutoComplete2 = (text) => {
    const params = {
      name: text,
      catalog_id: catalogId || catalog?.id,
      brand_name: ref.current.brand_name || text,
      molecule: ref.current.molecule || text,
    };
    if (!config?.features?.show_all) {
      params.is_available = true;
    }
    SearchManager.strictSearch(
      params,
      (response) => {
        dispatch({
          payload: liftReleventQueryStartingWith(response.hits.hits, text),
          type: "SET_AUTO_COMPLETE_RESULTS",
        });
      },
      () => {}
    );
  };

  const onSearchChange = (text) => {
    if (text && text.length >= 3) {
      loadAutoComplete2(text);
    }
  };

  const onSearchFocus = () => {
    AnalyticsManager.send("category_search");
  };

  const renderAutoCompleteItem = ({ item, index }) => {
    return (
      <View key={`index-${index}-query`}>
        <RowButton
          title={item._source.name}
          rightTitle={""}
          onPress={() => {
            onSearchItemPress(item._source.name);
            Keyboard.dismiss();
            dispatch({
              payload: [],
              type: "SET_AUTO_COMPLETE_RESULTS",
            });
          }}
          showDisclosure={false}
        />
        <LineDivider dividerColor="#DADADA" width="100%" theme={theme} />
      </View>
    );
  };

  const [keyboardOpen, setKeyboardOpen] = useState(false);

  useEffect(() => {
    const keyboardDidShowListener = Keyboard.addListener(
      "keyboardDidShow",
      () => {
        setKeyboardOpen(true);
      }
    );
    const keyboardDidHideListener = Keyboard.addListener(
      "keyboardDidHide",
      () => {
        setKeyboardOpen(false);
        dispatch({
          payload: [],
          type: "SET_AUTO_COMPLETE_RESULTS",
        });
      }
    );
    return () => {
      keyboardDidShowListener.remove();
      keyboardDidHideListener.remove();
    };
  }, [dispatch]);

  useEffect(() => {
    if(!keyboardOpen){
      dispatch({
        payload: [],
        type: "SET_AUTO_COMPLETE_RESULTS",
      });
    }
  }, [keyboardOpen]);

  // useEffect(() => {
  //   const keyboardDidHideListener = Keyboard.addListener(
  //     "keyboardDidHide",
  //     () => {
  //       dispatch({
  //         payload: [],
  //         type: "SET_AUTO_COMPLETE_RESULTS",
  //       });
  //     }
  //   );

  //   return () => {
  //     keyboardDidHideListener.remove();
  //   };
  // }, []);

  const renderAutoComplete = () => {
    return (
      <View
        style={{
          backgroundColor: "transparent",
          position: "absolute",
          height: 300,
          top: 10,
          left: 0,
          width: "100%",
          flexDirection: "column",
          alignItems: "center",
          zIndex: 100
        }}
      >
        <View
          style={{
            backgroundColor: "white",
            position: "absolute",
            height: "100%",
            width: "95%",
            borderRadius: 10,
            borderWidth: 1,
            borderColor: "lightgray",
          }}
        >
          <FlatList
            keyExtractor={(_, index) => "index-" + index}
            data={state.auto_complete_results}
            renderItem={renderAutoCompleteItem}
            layout={"default"}
            showsHorizontalScrollIndicator={false}
            keyboardShouldPersistTaps="handled"
          />
        </View>
      </View>
    );
  };
  let searchResultsTitle =
    state.query ||
    state.company_name ||
    state.region ||
    state.brand_name ||
    state.molecule ||
    state.therapy_area_simple ||
    "";

  if (state.is_new) {
    searchResultsTitle = "New on Zip";
  } else if (state.bestsellers) {
    searchResultsTitle = "Best sellers";
  }

  const scrollListToTop = () => {
    // TODO: Will throw error when no items are available: scrollToIndex out of range: item length 0 but minimum is 1
    try {
      if (itemsListingRef.current) {
        itemsListingRef.current?.scrollToIndex({
          index: 0,
        });
      }
    } catch (error) {}
  };

  return (
    <LoadingContainer loading={loading || screenLoading}>
      <SearchableScreen
        onFocus={onSearchFocus}
        onSearchChange={onSearchChange}
        showOptions={!isSearchEmpty()}
        canGoback={true}
        onBack={onBack}
        onSearch={onSearch}
        onSort={onSort}
        sortEnabled={false}
        onFilter={onFilter}
        inputref={searchInputRef}
        placeholder={I18n.t("screen_messages.common.search_with_query", {
          query: searchParams.category?.name || searchResultsTitle,
        })}
      >
        {/* {state.auto_complete_results?.length > 0 ? renderAutoComplete() : null} */}
        {keyboardOpen && state.auto_complete_results?.length > 0
          ? renderAutoComplete()
          : null}
        {isSearchEmpty() ? (
          renderEmptySearchPage()
        ) : (
          <View
            style={{
              flex: 1,
              width: "100%",
            }}
          >
            {/* <KeyboardAvoidingView behavior="height" enabled /> */}
            <FlatList
              data={results}
              renderItem={renderItem}
              keyExtractor={(item) => item._source.id}
              style={{ flex: 1, paddingTop: 5, height: windowHeight - 150 }}
              ListFooterComponent={() => {
                return <VerticalSpacing size={100} />;
              }}
              ListHeaderComponent={() => {
                return (
                  <View>
                    {/* <DealsComponent /> */}
                    {results.length > 0 &&
                    ((state.query && state.query.length > 0) ||
                      state.is_new ||
                      state.bestsellers) ? (
                      <View
                        style={{ paddingHorizontal: theme.containerPadding }}
                      >
                        <PrestoText
                          fontStyle="semibold"
                          size={14}
                          color={theme.primary}
                        >
                          {searchResultsTitle
                            ? I18n.t(
                                "screen_messages.common.search_results_for",
                                {
                                  query: searchResultsTitle,
                                }
                              )
                            : I18n.t(
                                "screen_messages.common.search_results_default_prefix"
                              )}
                        </PrestoText>
                      </View>
                    ) : null}
                    {results.length == 0 && !loading && pageLoaded > 0 ? (
                      <View
                        style={{
                          paddingHorizontal: theme.containerPadding,
                        }}
                      >
                        <PrestoText
                          fontStyle="semibold"
                          size={14}
                          color={theme.primary}
                        >
                          {searchResultsTitle
                            ? I18n.t(
                                "screen_messages.common.no_search_results_for",
                                {
                                  query: searchResultsTitle,
                                }
                              )
                            : I18n.t(
                                "screen_messages.common.no_search_results_default_prefix"
                              )}
                        </PrestoText>
                      </View>
                    ) : null}
                  </View>
                );
              }}
              onEndReached={
                results.length > 0
                  ? () => {
                      if (!useMultiSearch) {
                        loadNextPage();
                      }
                    }
                  : undefined
              }
              onEndReachedThreshold={0.25}
              ref={(ref) => {
                itemsListingRef.current = ref;
              }}
            />
          </View>
        )}
      </SearchableScreen>
    </LoadingContainer>
  );
}
